<template>
	<div>
		<div class='a-booking a-content a-faq'>
			<div class='app-container app-container-fluid a-container'>
				<div class='a-booking-container'>
					<div class='a-booking-content'>
						<p class='a-heading-1'>FAQ</p>
						<div class="row a-faq-box">
							<div class="col">
								<div v-for="(item, i) in items" :key="i">
									<v-accordion hideTriggerIcon>
										<template v-slot:title>
											<v-icon icon="arrow-down" :size="14"/>
											{{ item.title }}
										</template>

										<template v-slot:content>
											<p>{{ item.content }}</p>
										</template>
									</v-accordion>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	data: () => ({
		Accordion: {
			count: 0,
			active: null,
		},
		items: [
			{
				"title": "What services does your tourist website provide?",
				"content": "Our tourist website offers a wide range of services, including hotel bookings, flight reservations, tour packages, car rentals, and more. We also provide information on popular tourist destinations, attractions, and activities."
			},
			{
				"title": "How can I make a booking on your website?",
				"content": "Making a booking on our website is easy. Simply browse through our listings, select the desired service (hotel, flight, tour, etc.), choose your dates and preferences, and follow the prompts to complete the booking process. You'll receive a confirmation email with all the details once your booking is confirmed."
			},
			{
				"title": "What payment methods do you accept?",
				"content": "We accept various payment methods, including credit cards (Visa, Mastercard, American Express), debit cards, and online payment platforms such as PayPal. The available payment options will be displayed during the checkout process."
			},
			{
				"title": "Are there any additional fees or taxes added to the booking price?",
				"content": "The booking price displayed on our website usually includes all applicable taxes and fees. However, in some cases, certain destinations may have additional local taxes or resort fees that are not included in the initial price. Any such fees will be clearly mentioned during the booking process before you confirm your reservation."
			},
			{
				"title": "What is your cancellation policy?",
				"content": "Our cancellation policy may vary depending on the service and provider. Generally, we offer flexible cancellation options for most bookings, allowing you to cancel or modify your reservation within a certain timeframe without incurring any penalties. However, some bookings may have specific terms and conditions, so it's important to review the cancellation policy during the booking process or refer to the specific provider's policy."
			},
			{
				"title": "How can I contact your customer support team?",
				"content": "If you have any questions, concerns, or need assistance, our customer support team is available to help. You can reach us through the contact form on our website, via email at support@travel-engine.gb.net, or by calling our toll-free helpline at +1-800-123-4567. We strive to provide prompt and helpful assistance to all our customers."
			}
		]
	}),
	provide() {
		return {Accordion: this.Accordion};
	},
}
</script>
<style lang="scss">
@import '@/styles/global/variables';
@import '@/styles/global/mixins';

.a-faq-box {
	margin-top: 15px;

	.app-accordion {
		&__title {
			padding: 15px 0;
			@include minw($grid-breakpoints-xs) {
				padding: 20px 0;
			}

			.heading-4 {
				display: flex;
				align-items: center;
				font-weight: 400;
				@include minw($grid-breakpoints-lg) {
					font-size: 18px;
				}

				.app-icon {
					width: 20px !important;
					height: 20px !important;
					flex-shrink: 0;
					margin-right: 25px;
					color: $green-persian;
					transition: transform $transition;
				}
			}
		}

		&__trigger_active {
			.app-icon {
				transform: rotate(180deg);
			}
		}

		&__content {
			padding: 15px 0 30px;
			border: none;
			@include minw($grid-breakpoints-xs) {
				padding: 20px 0 40px 40px;
			}
		}

		&__item {
			border-bottom: 1px solid rgba($black, 0.1);
			border-radius: 0;
			margin-bottom: 0;
		}
	}
}


</style>
